import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './js/serviceWorker';
import { Provider } from 'react-redux';

//Global
import AddItem from './js/Components/AddItem';

//Bookmark
import AddBookmark from './js/Components/Bookmark/AddBookmark';
import BookmarkList from './js/Components/Bookmark/BookmarkList';
import AddToCart from './js/Components/Bookmark/AddToCart';

//Mobile
import MobileCartList from "./js/Components/CartAside/MobileCartList";
import MobileCartCounter from './js/Components/CartAside/MobileCartCounter';
//Mobile

//Cart
import CartPriceContainer from './js/Components/Cart/CartPriceContainer';
import CartList from './js/Components/Cart/CartList';
import CartCounter from './js/Components/Cart/CartCounter';
//Cart

//Checkout
import CheckoutList from './js/Components/Checkout/CheckoutList';

//Stores
import { shopCartStore } from './js/Stores/ShopCart';
import { shopCartListStore } from './js/Stores/ShopCartList';
import { bookmarkStore } from './js/Stores/Bookmark';


if (document.getElementById('navButtonCart')) {
    ReactDOM.render(
        <Provider store={shopCartStore}><CartCounter/></Provider>,
        document.getElementById('navButtonCart')
    );
}

if (document.getElementById('sideCart')) {

  ReactDOM.render(
      <Provider store={shopCartStore}><MobileCartList/></Provider>,
      document.getElementById('sideCart')
  );
}

if (document.getElementById('cartButton')) {

  ReactDOM.render(
      <Provider store={shopCartStore}><MobileCartCounter/></Provider>,
      document.getElementById('cartButton')
  );
}

if (document.getElementById('cartSum')) {
  ReactDOM.render(
      <Provider store={shopCartStore}><CartPriceContainer/></Provider>,
      document.getElementById('cartSum')
  );
}

if (document.getElementById('shopCartList')) {
  ReactDOM.render(
      <Provider store={shopCartStore}><CartList/></Provider>,
      document.getElementById('shopCartList')
  );
}

if (document.getElementById('oneStepCheckoutCartProducts')) {
  ReactDOM.render(
      <Provider store={shopCartListStore}><CheckoutList/></Provider>,
      document.getElementById('oneStepCheckoutCartProducts')
  );
}

if (document.getElementById('addToCartButton')) {
  ReactDOM.render(
      <AddItem/>,
      document.getElementById('addToCartButton')
  );
}

if (document.getElementById('quickorder')) {
  ReactDOM.render(
    <Provider store={bookmarkStore}><BookmarkList/></Provider>,
    document.getElementById('quickorder')
  );

}

if (document.getElementById('addListToCartButton')) {
  ReactDOM.render(
    <Provider store={bookmarkStore}><AddToCart/></Provider>,
    document.getElementById('addListToCartButton')
  );

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
