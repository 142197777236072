import React from 'react';
import {connect} from 'react-redux';
import {shopCartListStore} from '../../Stores/ShopCartList';
import {shopCartStore} from "../../Stores/ShopCart";

class ItemsSubtotal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            priceNetto: props.priceNetto || 0.00,
            priceNettoTotal: props.priceNettoTotal || 0.00,
            priceBrutto: props.priceBrutto || 0.00,
            priceBruttoTotal: props.priceBruttoTotal || 0.00,
            priceNettoDisplay: props.priceNettoDisplay || 0.00,
            priceNettoTotalDisplay: props.priceNettoTotalDisplay || 0.00,
            priceBruttoDisplay: props.priceBruttoDisplay || 0.00,
            priceBruttoTotalDisplay: props.priceBruttoTotalDisplay || 0.00,
            catalogId: props.catalogId || null,
            quantity: props.quantity || 1,
            apiId: props.apiId,
            status1: props.status1 || 1,
        };
    }

    mapStateToProps = (state) => {
        return {
            article: shopCartListStore.getState(),
            cart: shopCartStore.getState()
        }
    };
    subtotal = (props) => {
        let a = 0.00;
        let b = 0.00;
        props.article.forEach(function (item) {

            if (item.status1 == 1) {
                a = parseFloat(a) + parseFloat(item.priceNettoTotal)
            } else {
                b = parseFloat(b) + parseFloat(item.priceNettoTotal)
            }
        })
        let consultationItemSum = a.toFixed(2).replace(".", ",");
        let itemSum = b.toFixed(2).replace(".", ",");

        // if (props.article.status1 != undefined) {

        return (
            <ul className="shopCartItemList mb-3">
                <li className="shopCartItem">
                    <div className="product text-right"><strong
                        className="d-inline-block mr-5">Zwischensumme</strong>
                        {itemSum} &nbsp;{props.cart.currencySymbol}
                    </div>
                </li>
            </ul>
        )
        // } else {
        //     return null;
        // }
    }
    connectedProduct = connect(this.mapStateToProps)(this.subtotal);

    render() {
        return (
            <this.connectedProduct/>
        );
    }
}

export default ItemsSubtotal;