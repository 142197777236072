import React from 'react';
import { connect } from 'react-redux';

import ConsultationItem from "./ConsultationItem";


class ConsultationItems extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            products: []
        };
    }

    mapStateToProps = (state) => {
        return {
            products: state,
        };
    };

    itemList = (props) => {
        var items = props.products.map(
            (item, key) => {
                    return (
                        <ConsultationItem
                            key={key}
                            objId={key}
                            test={key}
                            articleId={item.articleId}
                            articleImages={item.articleImages}
                            articleName={item.articleName}
                            articlenumber={item.articlenumber}
                            catalogId={item.catalogId}
                            priceBrutto={item.priceBrutto}
                            priceBruttoDisplay={item.priceBruttoDisplay}
                            priceBruttoTotal={item.priceBruttoTotal}
                            priceBruttoTotalDisplay={item.priceBruttoTotalDisplay}
                            priceNetto={item.priceNetto}
                            priceNettoDisplay={item.priceNettoDisplay}
                            priceNettoTotal={item.priceNettoTotal}
                            priceNettoTotalDisplay={item.priceNettoTotalDisplay}
                            quantity={item.quantity}
                            status1 ={item.status1}
                        />
                    );
            }
        );
        return items;
    }

    connectedCartList = connect(this.mapStateToProps)(this.itemList);

    render() {
        return (
                <React.Fragment>
                    <this.connectedCartList />
                </React.Fragment>
        );
    }

}

export default ConsultationItems;