import React from 'react';
import { connect } from 'react-redux';
import RemoveItem from '../RemoveItem';

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            test: props.test,
            articleId: props.articleId || null,
            articleName: props.articleName || '',
            articleImages: props.articleImages || [],
            articlenumber: props.articlenumber || '',
            priceNetto: props.priceNetto || 0.00,
            priceNettoTotal: props.priceNettoTotal || 0.00,
            priceBrutto: props.priceBrutto || 0.00,
            priceBruttoTotal: props.priceBruttoTotal || 0.00,
            priceNettoDisplay: props.priceNettoDisplay || 0.00,
            priceNettoTotalDisplay: props.priceNettoTotalDisplay || 0.00,
            priceBruttoDisplay: props.priceBruttoDisplay || 0.00,
            priceBruttoTotalDisplay: props.priceBruttoTotalDisplay || 0.00,
            catalogId: props.catalogId || null,
            quantity: props.quantity || 1,
            apiId: props.apiId,
        };
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    item = (props) => {
        if(props.article.articleId != null){
            var articleDetailLink = '/checkout/cart-detail,3,a,' + props.article.articleId + ",k," + props.article.catalogId + '.html';
            var variantDetail = null;
            if(props.article.variantId != null){
                variantDetail = (<span class="itemVariant">{props.article.variantType} {props.article.variantName}</span>);
            }
                return (
                    <div class="cartItem">
                        <a href={articleDetailLink}>
                            <span class="itemQuntity">{props.article.quantity}x </span>
                            <span class="itemName">{props.article.articleName}</span>
                            {variantDetail}
                            <span class="itemPrice">{props.article.priceNettoTotalDisplay}&nbsp;€</span>
                        </a>
                        <RemoveItem test={this.state.test}/>
                    </div>
                );
        }else{
            return (
                null
            );
        }
    }
    connectedProduct = connect(this.mapStateToProps)(this.item);
    render() {
        return (
             <this.connectedProduct/>
        );
       
    }
}

export default Item;