import React from 'react';
import {connect, Provider} from 'react-redux';
import Items from './Items';
import CustomerComment from './CustomerComment';
import {shopCartListStore} from '../../Stores/ShopCartList';
import ConsultationItems from "./ConsultationItems";
import ConsultationItemsSubtotal from "./ConsultationItemsSubtotal";
import ItemsSubtotal from "./ItemsSubtotal";

class CartList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    mapStateToProps = (state) => {
        return {
            data: state,
            items: shopCartListStore.getState()
        }
    };

    emptyList = (props) => {
        if (props.data.articleCount == 0) {
            return (
                <React.Fragment>
                    <h1 className="h2 mt-2 mb-4 mt-md-4 mb-md-5">Der Warenkorb ist leer</h1>
                    <p>Sie haben keine Artikel im Warenkorb.</p>
                    <a href="/Praxisshop/index.html" class="btn shopSecondaryButton mb-3">Weiter einkaufen</a>
                </React.Fragment>
            );
        } else {

            return (
                <React.Fragment>
                    <Provider store={shopCartListStore}>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="h2 mt-2 mb-4 mt-md-4 mb-md-5">Mein Warenkorb</h1>
                            </div>
                        </div>
                        <ul id="shopCartItemList">
                            <Items/>
                        </ul>
                        {props.data.status1Count !== 0 && props.data.status1Count !== props.data.itemCount ?
                            <ItemsSubtotal/> : null}
                        {props.data.status1Count !== 0 ?
                            <>
                                <p><strong>Sprechstundenbedarf</strong></p>
                                <ul id="shopCartItemList">
                                    <ConsultationItems/>
                                </ul>
                                {props.data.status1Count !== props.data.itemCount ? <ConsultationItemsSubtotal/> : null}
                            </> : null}
                    </Provider>
                    <CustomerComment/>
                </React.Fragment>
            )
        }
    }


    connectedList = connect(this.mapStateToProps)(this.emptyList);

    render() {
        return (
            <this.connectedList/>
        );
    }
}

export default CartList;